import { useState, useContext, useEffect } from "react";
import { Box, Grid } from "@chakra-ui/layout";
import { Text, Progress, List, Button, Flex, useMediaQuery, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useSession } from "next-auth/react";
import { CustomListItem, Task } from "./custom-list-item";
import { getAccount } from "../../utils/sessionHelper";
import { TweetContext } from "../../context/tweetContext";
import { useOnboardingSteps } from "./useOnboardingSteps";
import { GrCirclePlay } from 'react-icons/gr';
import { useWindowWidth } from '@react-hook/window-size';
import { layerStyle, textStyle } from "theme/names";
import { BsPlayCircle } from "react-icons/bs";

/**
 * Note: don't forget to add "id" while adding a new task.
 * It's literally the most important thing to save task progress.
 */
const TASKLIST: Task[] = [
  {
    id: "aiInpiration",
    title: "Get AI-selected inspiration",
    link: "/auto",
    helperText:
      "Our AI automatically finds posts it believes to be relevant for you. You can edit the settings on the page if you feel like it's a bit off.",
  },
  {
    id: "searchInspiration",
    title: "Search for inspirations",
    link: "/search",
    helperText:
      "Search for keywords or @handles and find relevant posts with high engagement.",
  },
  {
    id: "aiWriting",
    title: "Try the AI-writing assistant",
    helperText:
      "Click the ⚡ icon at the top-right of any post in Taplio. Our AI will attempt to re-write that post and it will prefill the writing/scheduling module on the right of the screen.",
  },
  {
    id: "schedule",
    title: "Schedule a post",
    helperText:
      "Schedule or publish your first post with Taplio. Write a post in the text box, and choose an option: post now, schedule, or add to queue.",
  },
  // {
  //   id: "evergreen",
  //   title: "Set up Evergreen tweets",
  //   link: "/collections?tab=evergreen",
  //   isSkipable: true,
  //   helperText:
  //     "Evergreen tweets are tweets from your account that you regularly retweet automatically. This way, you're still active even when you're not.",
  // },
  {
    id: "engage",
    title: "Start engaging with people",
    link: "/engage",
    helperText:
      "Taplio helps you find great posts to engage with and will push your posts to the relevant people.",
  },
];

export function OnBoardPanel({onOpenWalkthrough}) {
  const { data: session } = useSession() ?? {}
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tweetContext: any = useContext(TweetContext);
  const { hidePanel, skipStep } = useOnboardingSteps();

  // const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  const onboarding = getAccount(session)?.onboarding;
  const completedSteps: string[] = onboarding?.completedSteps;
  const indexOfCurrentStep = TASKLIST.findIndex(
    (t) => t.id === onboarding?.currentStep
  );
  const stepsToShow = TASKLIST.slice(0, indexOfCurrentStep + 1);

  useEffect(() => {
    const onAllTasksComplete = async () => {
      if (completedSteps?.length === TASKLIST.length) {
        hidePanel();
      }
    };
    onAllTasksComplete();
  }, [completedSteps]);

  const handleClose = async () => {
    setIsLoading(true);
    await hidePanel();
    setIsLoading(false);
    tweetContext.refresher({});
  };

  const onboardingProgress = (completedSteps?.length * 100) / TASKLIST.length;

  return (
    (onboarding?.showPanel && !tweetContext.isFullModeOpen) && Boolean(session?.user?.data?.subStatus) === true && Boolean(session?.user?.data?.subscription) === true && (
        <Box
          layerStyle={layerStyle["bg.border.rounded"]}
          p={6}
          m={5}
        >
          <Grid gridTemplateColumns="1fr auto" gap={4}
          >
            <Text
              textStyle={textStyle["body.medium.standard"]}
              fontWeight="medium" mt="1">
              Hello 👋 ‎  Let's take your LinkedIn game to the next level! Follow
              these key steps ({indexOfCurrentStep + 1}/{TASKLIST.length})
            </Text>
            <Grid gap={2} gridTemplateColumns="auto auto">
              {
                !isTooSmall && (
                  <Button
                    mr={2}
                    variant="secondary"
                    // size="sm"
                    onClick={onOpenWalkthrough}
                    leftIcon={<BsPlayCircle fontSize="20px" />}
                  >
                    Demo Video
                  </Button>
                )
              }
              <IconButton
                aria-label="Close"
                // size="sm"
                // width="40px"
                // mr="-4"
                onClick={handleClose}
                isLoading={isLoading}
                variant="secondary"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Progress
            value={onboardingProgress}
            size="xs"
            // colorScheme="twitter"
            mx="-6"
            // my="3"
            my={6}
          />
          <List spacing={3} mb="3">
            {stepsToShow.map(({ id, title, helperText, link, isSkipable }) => (
              <CustomListItem
                title={title}
                helperText={helperText}
                isComplete={completedSteps?.includes(id)}
                link={link}
                id={id}
                isSkipable={isSkipable && id === onboarding?.currentStep}
                handleSkip={() => skipStep("engage")}
                key={id}
              />
            ))}
          </List>
        {/* </Box> */}
      </Box>
    )
  );
}
